import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { Title, translate, Authenticated, Loading } from "react-admin";
// import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { Eventcalendar, localeEs } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import moment from "moment-timezone";
import dataProvider from "../dataProvider";
import compose from "recompose/compose";
import { connect } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { MenuItem, Typography, Tooltip } from "@material-ui/core";
import InvertColorsIcon from "@material-ui/icons/InvertColors";

const GlobalStyle = withTheme(createGlobalStyle`
${({ theme, ...props }) => `
    .react-calendar-timeline .rct-header-root {
        background-color: ${theme.palette.primary.main};
    }
    .rct-sidebar-row {
        color: ${theme.palette.text.secondary};
    }
    .rct-dateHeader > span {
        color: white;
    }
    .booking {
        background: ${theme.palette.primary.main} !important;;
        border: ${theme.palette.primary.main} !important;
    }
    .blocked {
        background: red !important;;
        border: red !important;
    }
    .react-calendar-timeline  {
        width: ${props.sidebarOpen ? "80vw" : "95vw"};
        margin-top: 20px;
    }
    .rct-dateHeader {
        color: ${theme.palette.text.primary};
        background: ${theme.palette.primary.main} !important;;
    }
    @media (max-width: 768px) {
        .react-calendar-timeline  {
            width: 95vw;
        }
      }
      `}
`);

const Occupancy = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const viewOptions = [
    { value: "month", label: "Mes" },
    { value: "year", label: "Año" }
    // { value: "week", label: "Semana" },
    // { value: "day", label: "Día" }
];

const initialState = {
    newProperties: [],
    newEvents: [],
    newEventsNextYear: [],
    properties: [],
    events: [],
    occupancy: [],
    occupancyAmount: "N/A",
    loading: true,
    viewType: "month"
};

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    onBoundsChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
        const month = moment(visibleTimeStart).format("M");
        let year = moment(visibleTimeStart).format("Y");

        if (year !== moment().format("Y")) {
            this.setState({ occupancyAmount: "N/A" });
        } else {
            const index = month - 1;
            this.setState({ occupancyAmount: this.state.occupancy[index] });
        }

        updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    };
    componentDidMount() {
        // console.log("componentDidMount");
        const aMonthAgo = new Date();
        aMonthAgo.setDate(aMonthAgo.getDate() - 30);

        dataProvider
            .getList("calendars", {
                filter: { date_gte: aMonthAgo.toISOString() },
                sort: {},
                pagination: {}
            })
            .then(response => response.data)
            .then(calendar => {
                const properties = calendar.properties
                    ? calendar.properties.map(function (obj) {
                          return {
                              id: obj.id,
                              title: obj.propertyName
                          };
                      })
                    : [];

                const events = calendar.events
                    ? calendar.events.map(function (obj) {
                          return {
                              id: obj.id,
                              group: obj.propertyId,
                              start_time: moment(obj.start),
                              end_time: moment(obj.end),
                              title: obj.type === 1 ? obj.bookingChannel : "Blocked",
                              className: obj.type === 1 ? "booking" : "blocked",
                              arrivalTime: obj.arrivalTime,
                              departureTime: obj.departureTime,
                              cleaningDate: obj.cleaningDate
                          };
                      })
                    : [];

                const userTimeZone = moment.tz.guess();

                this.setState({
                    newProperties: properties.map(property => ({ id: property.id, name: property.title })),
                    newEvents: events.map(event => ({
                        start: event.start_time,
                        end: moment(event.end_time).tz(userTimeZone).add(1, "day"),
                        title: event.title,
                        resource: event.group,
                        arrivalTime: event.arrivalTime,
                        departureTime: event.departureTime,
                        cleaningDate: event.cleaningDate
                    })),
                    newEventsNextYear: events.map(event => ({
                        start: event.start_time,
                        end: event.end_time,
                        title: event.title,
                        resource: event.group,
                        arrivalTime: event.arrivalTime,
                        departureTime: event.departureTime,
                        cleaningDate: event.cleaningDate
                    })),
                    properties: properties,
                    events: events,
                    occupancy: calendar.occupancy,
                    occupancyAmount: calendar.occupancy[moment().format("M") - 1],
                    loading: false
                });
            });
    }

    // componentDidUpdate() {
    //     console.log("componentDidUpdate");
    //     if (this.state.events.length > 0) this.setState(initialState);
    // }

    // componentWillUnmount() {
    //     console.log("componentWillUnmount");
    // }

    render() {
        const {
            translate,
            location,
            admin: { ui }
        } = this.props;

        const customBooking = data => {
            const ev = data.original;
            const isBlocked = ev.title === "Blocked";

            return (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: isBlocked ? "center" : "space-between",
                        height: "40px",
                        bgcolor: !isBlocked ? "#d1edfe" : "#ef5350",
                        clipPath: "polygon(10% 0, 90% 0, 100% 0, 90% 100%, 10% 100%, 0 100%)",
                        px: "10%"
                    }}
                >
                    {!isBlocked && (
                        <Box>
                            <Typography variant="caption" fontWeight="normal" style={{ fontSize: "9px", color: "black" }}>
                                C/I {ev.arrivalTime || ""}
                            </Typography>
                        </Box>
                    )}
                    <Box>
                        <Typography variant="body1" style={{ color: !isBlocked ? "black" : "white" }}>
                            {ev.title}
                        </Typography>
                    </Box>
                    {!isBlocked && (
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                            <Tooltip title={`Limpieza: ${ev.cleaningDate}`} placement="top">
                                <InvertColorsIcon style={{ color: "black", fontSize: "12px" }} fontSize="small" />
                            </Tooltip>
                            <Typography variant="caption" fontWeight="normal" style={{ fontSize: "9px", color: "black", margin: 0, padding: 0 }}>
                                C/O {ev.departureTime || ""}
                            </Typography>
                        </Box>
                    )}
                </Box>
            );
        };

        return (
            <Authenticated location={location}>
                <Card>
                    <Title title={translate("resources.calendar.name")} />
                    <CardContent>
                        <TextField
                            label="View"
                            style={{ width: "150px", marginBottom: "1rem" }}
                            variant="outlined"
                            select
                            value={this.state.viewType}
                            onChange={e => this.setState({ ...this.state, viewType: e.target.value })}
                        >
                            {viewOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                        <div style={{ display: "grid" }}>
                            <GlobalStyle sidebarOpen={ui.sidebarOpen} />

                            {!this.state.loading ? (
                                <>
                                    <Eventcalendar
                                        clickToCreate={false}
                                        dragToCreate={false}
                                        dragToMove={false}
                                        dragToResize={false}
                                        eventDelete={false}
                                        theme="ios"
                                        themeVariant="light"
                                        locale={localeEs}
                                        view={{
                                            timeline: {
                                                type: this.state.viewType,
                                                weekNumbers: true,
                                                currentTimeIndicator: false
                                            }
                                        }}
                                        renderScheduleEvent={customBooking}
                                        data={this.state.newEvents}
                                        resources={this.state.newProperties}
                                        min={moment().startOf("year").toDate()} // primer día del año siguiente
                                        max={moment().endOf("year").toDate()} // último día del año siguiente
                                        className="rounded"
                                        showToday={this.state.viewType !== "year"}
                                    />
                                    <Occupancy>
                                        {translate("resources.calendar.occupancy")}: {isNaN(this.state.occupancyAmount) ? this.state.occupancyAmount : `${this.state.occupancyAmount}%`}
                                    </Occupancy>
                                    <Eventcalendar
                                        clickToCreate={false}
                                        dragToCreate={false}
                                        dragToMove={false}
                                        dragToResize={false}
                                        eventDelete={false}
                                        theme="ios"
                                        themeVariant="light"
                                        locale={localeEs}
                                        view={{
                                            timeline: {
                                                type: this.state.viewType,
                                                weekNumbers: true,
                                                currentTimeIndicator: false
                                            }
                                        }}
                                        renderScheduleEvent={customBooking}
                                        data={this.state.newEventsNextYear}
                                        resources={this.state.newProperties}
                                        min={moment().startOf("year").add(1, "year").toDate()} // primer día del año siguiente
                                        max={moment().endOf("year").add(1, "year").toDate()} // último día del año siguiente
                                        className="rounded"
                                        showToday={false}
                                    />
                                    <Occupancy>
                                        {translate("resources.calendar.occupancy")}: {isNaN(this.state.occupancyAmount) ? this.state.occupancyAmount : `${this.state.occupancyAmount}%`}
                                    </Occupancy>
                                </>
                            ) : (
                                <Loading />
                            )}
                        </div>
                    </CardContent>
                </Card>
            </Authenticated>
        );
    }
}

const mapStateToProps = state => ({
    admin: state.admin
});

const enhance = compose(connect(mapStateToProps), translate);

export default enhance(Calendar);
