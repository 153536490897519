import React from "react";
import { Responsive, Authenticated, Title, Loading, Error, useTranslate, useGetIdentity, useLocale, useQuery } from "react-admin";
import { Bar } from "@reactchartjs/react-chart.js";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import Welcome from "./Welcome";
import Revenue from "./Revenue";
import NumberBookings from "./NumberBookings";
import NumberProperties from "./NumberProperties";
import Rating from "./Rating";

const useStyles = makeStyles({
    flex: { display: "flex" },
    flexColumn: { display: "flex", flexDirection: "column" },
    leftCol: { flex: 1, marginRight: "1em" },
    singleCol: { marginTop: "2em", marginBottom: "2em" }
});

const Dashboard = ({ permissions }) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = useStyles();

    moment.locale(locale);

    const theme = useTheme();

    const { identity, loading: identityLoading } = useGetIdentity();

    const { data, loading, error } = useQuery({
        type: "get",
        resource: "metrics"
    });

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    };

    if (loading || identityLoading) return <Loading />;
    if (error) return <Error error="misc.fetchError" />;
    if (!data) return null;

    const {
        totalRevenue,
        numberBookings,
        numberProperties,
        totalRating,
        charts: { revenue, adr, occupancy }
    } = data;

    return (
        <Authenticated>
            <React.Fragment>
                <Title title={translate("resources.dashboard.name")} />

                <Responsive
                    xsmall={
                        <div>
                            <div className={classes.flexColumn}>
                                <div style={{ marginBottom: "2em" }}>
                                    <Welcome name={identity.fullName} />
                                </div>
                                <div className={classes.flex}>
                                    {permissions === "variable" && <Revenue value={totalRevenue} />}
                                    <NumberBookings value={numberBookings} />
                                </div>
                            </div>
                        </div>
                    }
                    small={
                        <div className={classes.flexColumn}>
                            <div className={classes.singleCol}>
                                <Welcome name={identity.fullName} />
                            </div>
                            <div className={classes.flex}>
                                {permissions === "variable" && <Revenue value={totalRevenue} />}
                                <NumberBookings value={numberBookings} />
                            </div>
                        </div>
                    }
                    medium={
                        <div className={classes.flex}>
                            <div className={classes.leftCol}>
                                <div className={classes.flex}>
                                    {permissions === "variable" && <Revenue value={totalRevenue} />}
                                    <NumberBookings value={numberBookings} />
                                    <NumberProperties value={numberProperties} />
                                    <Rating value={totalRating} />
                                </div>
                                <div className={classes.singleCol}>
                                    <Welcome name={identity.fullName} />
                                </div>
                            </div>
                        </div>
                    }
                />

                {permissions === "variable" && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography>{translate("resources.dashboard.charts.revenue")}</Typography>
                                <Bar
                                    data={{
                                        labels: moment.months(),
                                        datasets: [
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.currentYear"),
                                                data: revenue.current,
                                                borderColor: "white",
                                                backgroundColor: theme.palette.primary.main
                                            },
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.previousYear"),
                                                data: revenue.previous,
                                                backgroundColor: theme.palette.secondary.main,
                                                borderColor: "white"
                                            }
                                        ]
                                    }}
                                    options={options}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography>{translate("resources.dashboard.charts.adr")}</Typography>
                                <Bar
                                    data={{
                                        labels: moment.months(),
                                        datasets: [
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.currentYear"),
                                                data: adr.current,
                                                borderColor: "white",
                                                backgroundColor: theme.palette.primary.main
                                            },
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.previousYear"),
                                                data: adr.previous,
                                                backgroundColor: theme.palette.secondary.main,
                                                borderColor: "white"
                                            }
                                        ]
                                    }}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Typography>{translate("resources.dashboard.charts.occupancy")}</Typography>
                                <Bar
                                    data={{
                                        labels: moment.months(),
                                        datasets: [
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.currentYear"),
                                                data: occupancy.current,
                                                borderColor: "white",
                                                backgroundColor: theme.palette.primary.main
                                            },
                                            {
                                                type: "bar",
                                                label: translate("resources.dashboard.charts.previousYear"),
                                                data: occupancy.previous,
                                                backgroundColor: theme.palette.secondary.main,
                                                borderColor: "white"
                                            }
                                        ]
                                    }}
                                    options={options}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </React.Fragment>
        </Authenticated>
    );
};

export default Dashboard;
