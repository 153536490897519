import React from "react";
import { List, Datagrid, TextField, Filter, DateField, DateInput, ReferenceInput, NumberInput, SelectInput, ShowButton } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";

const filterStyles = {
    status: { width: 150 }
};

const PropertiesFilter = withStyles(filterStyles)(({ classes, ...props }) => (
    <Filter {...props}>
        <ReferenceInput source="propertyId" reference="properties">
            <SelectInput optionText={choice => `${choice.propertyName}`} />
        </ReferenceInput>
        <NumberInput source="propertyCapacity" />
        <NumberInput source="propertyNumBedrooms" />
        <NumberInput source="propertyAverage" />
        <DateInput source="createdDate" />
    </Filter>
));

const PropertiesList = ({ classes, ...props }) => (
    <List {...props} bulkActionButtons={false} filters={<PropertiesFilter />}>
        <Datagrid>
        <TextField source="propertyName" />

            <TextField source="propertyCapacity" />
            <TextField source="propertyNumBedrooms" />
            <TextField source="propertyAverage" />
            <DateField locales="en-GB" source="createdDate" />

            <ShowButton />
        </Datagrid>
    </List>
);

export default PropertiesList;
