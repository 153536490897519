export const darkTheme = {
    palette: {
        primary: { main: "#425e9e", contrastText: "#ffffff" },
        secondary: { main: "#53c3e6", contrastText: "#ffffff" },
        type: "dark" // Switching the dark mode on is a single property value change.
    }
};

export const lightTheme = {
    palette: {
        primary: { main: "#425e9e", contrastText: "#ffffff" },
        secondary: { main: "#53c3e6", contrastText: "#000000" }
    }
};
