import React, { useState } from "react";
import { TextField, SimpleShowLayout, useShowController, useTranslate, showNotification, useDataProvider, Title, Loading } from "react-admin";
import Button from "@material-ui/core/Button";
import TextFieldMUI from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

const Channels = ({ source, record = {} }) => {
    const translate = useTranslate();

    return (
        <div style={{ marginTop: 10 }}>
            <Typography variant="caption" color="textSecondary" display="block">
                {translate("resources.properties.fields.propertyChannel")}
            </Typography>
            {record[source].map(channel => {
                return (
                    <Chip key={channel.channelId} style={{ marginRight: 5, marginTop: 5 }} size="small" label={channel.bookingChannel} component="a" target="_blank" href={channel.link} clickable />
                );
            })}
        </div>
    );
};

const Photos = ({ source, record = {} }) => {
    const translate = useTranslate();

    if (!record) {
        return null;
    }

    return (
        <div style={{ marginTop: 10 }}>
            <Typography variant="caption" color="textSecondary" display="block">
                {translate("resources.properties.fields.propertyPhotos")}
            </Typography>
            {record[source].thumb.map((image, index) => {
                return (
                    <a target="_blank" href={image} key={index} rel="noopener noreferrer">
                        <img src={image} alt={image.split("/").pop()} height="100" width="100" style={{ marginRight: 10, borderRadius: 5 }} />
                    </a>
                );
            })}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        marginTop: "1.1em"
    },
    card: {
        padding: 16
    }
});

const MyShow = props => {
    const { basePath, loading, record, resource, version } = useShowController(props);

    const dispatch = useDispatch();
    const dataProvider = useDataProvider();

    const translate = useTranslate();
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState("");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles(props);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function saveChanges() {
        dataProvider
            .post("report", { data: { propertyId: props.id, comment: comment } })
            .then(({ data }) => {
                dispatch(showNotification("resources.properties.feedbackSent"));
                setComment("");
                handleClose();
            })
            .catch(error => {
                dispatch(showNotification("resources.properties.feedbackError", "warning"));
                handleClose();
            });
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <Card className={classes.root}>
            <Title title={record ? `${record.propertyName}` : ""} />
            {React.cloneElement(props.children, {
                basePath,
                record,
                resource,
                version
            })}
            <div className={classes.card}>
                <Button color="primary" variant="contained" onClick={handleClickOpen}>
                    {translate("resources.properties.reportIssue")}
                </Button>
            </div>

            <Dialog open={open} fullScreen={fullScreen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{translate("resources.properties.reportIssue")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{translate("resources.properties.reportIssueDesc")}</DialogContentText>

                    <TextFieldMUI
                        autoFocus
                        fullWidth
                        label={translate("resources.properties.reportIssueComment")}
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                        inputProps={{
                            name: "comment",
                            id: "comment"
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {translate("ra.action.cancel")}
                    </Button>
                    <Button onClick={saveChanges} color="primary" disabled={!comment.length}>
                        {translate("resources.properties.reportIssue")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

const PropertiesShow = props => {
    return (
        <MyShow {...props}>
            <SimpleShowLayout>
                <TextField source="propertyName" />
                <TextField source="propertyAddress" />
                <TextField source="propertyNumBedrooms" />
                <TextField source="propertyNumBathrooms" />
                <TextField source="propertySize" />
                <TextField source="propertyLicense" />
                <TextField source="propertyCadastral" />
                <TextField source="propertyAverage" />
                <Channels source="channels" />
                <Photos source="photos" />
            </SimpleShowLayout>
        </MyShow>
    );
};

export default PropertiesShow;
