import englishMessages from "ra-language-english";

const messages = {
    ...englishMessages,
    auth: {
        reset: "Reset password",
        sent_reset: "Send password",
        reset_error: "Error sending password",
        reset_success: "New password sent to your email"
    },
    resources: {
        settings: {
            name: "Settings",
            language: "Language",
            theme: {
                name: "Theme",
                light: "Light",
                dark: "Dark"
            }
        },
        dashboard: {
            name: "Dashboard",
            revenue: "Revenue",
            num_bookings: "Bookings",
            num_properties: "Properties",
            rating: "Rating",
            welcome: {
                title: "Welcome",
                subtitle: "This is your personalised dasboard where you can view information related to your property/ies"
            },
            charts: {
                occupancy: "Occupancy (%)",
                adr: "ADR",
                revenue: "Revenue",
                currentYear: "Current year",
                previousYear: "Previous year"
            }
        },
        bookings: {
            name: "Booking |||| Bookings",
            fields: {
                createdDate: "Created",
                arrivalDate: "Arrival",
                arrivalTime: "Arrival Time",
                departureDate: "Departure",
                departureTime: "Departure Time",
                totalPrice: "Total",
                propertyId: "Property",
                numPax: "Pax",
                numNights: "Nights",
                bookingChannel: "Channel",
                month: "Month"
            }
        },
        reviews: {
            name: "Review |||| Reviews",
            fields: {
                reviewDate: "Date",
                propertyId: "Property",
                comment: "Comment",
                commentPrivate: "Private Comment",
                rating: "Rating",
                bookingChannel: "Channel",
                sourceName: "Review Source",
                reviewDateRange: "Date range",
                month: "Month"
            }
        },
        properties: {
            name: "Property |||| Properties",
            fields: {
                propertyName: "Property Name",
                propertyCapacity: "Capacity",
                propertyNumBedrooms: "Bedrooms",
                propertyNumBathrooms: "Bathrooms",
                propertyAverage: "Avg. Rating",
                createdDate: "Created",
                propertyLicense: "License num",
                propertyCadastral: "Cadastral num",
                propertySize: "Size (m2)",
                propertyAddress: "Address",
                propertyChannel: "Channels",
                propertyPhotos: "Photos"
            },
            reportIssue: "Report Issue",
            reportIssueComment: "Your comment",
            reportIssueDesc: "Please report any inaccuracies, mistakes or add any feedback",
            feedbackSent: "Feedback sent",
            feedbackError: "Error sending feedback"
        },
        calendar: {
            name: "Calendar",
            properties: "Properties",
            occupancy: "Occupancy"
        },
        maintenance: {
            name: "Maintenance",
            fields: {
                propertyId: "Property",
                taskDesc: "Task",
                taskCreation: "Created",
                month: "Month",
                taskCreationRangeOptions: {
                    currentMonth: "Current Month"
                },
                taskPaidBy: "Paid by"
            }
        },
        items: {
            name: "Items",
            fields: {
                propertyId: "Property",
                productName: "Product",
                productQuantity: "Quantity",
                productCost: "Cost",
                dateOut: "Date",
                month: "Month",
                storagePaidBy: "Paid by"
            },
            amountTotal: "Total amount:",
            costTotal: "Total cost:"
        },
        user: {
            name: "User",
            save: "Save Changes",
            email: "Email",
            saveSuccess: "Change saved",
            saveError: "Error saving changes"
        },
        utilities: {
            name: "Utilities",
            property: "Property",
            gas: "Gas",
            provider_name: "Provider Name",
            title_name: "Title Name",
            cif: "CIF",
            cups: "CUPS",
            electricity: "Electricity",
            water: "Water",
            internet: "Internet",
            insurance: "Insurance",
            line_number: "Line Number",
            policy_number: "Policy number",
            request_change: "Request Change",
            saveSuccess: "Request sent",
            saveError: "Error sending equest",
            select_property: "Select a property"
        },
        reports: {
            name: "Reports",
            label: "Name",
            from: "From",
            to: "To",
            nights: "Nights",
            people: "People",
            channel: "Channel",
            revenue: "Revenue",
            commissionCompany: "Commission Stay U-nique",
            channelCommission: "Commission Channel",
            cleaning: "Cleaning",
            laundry: "Laundry",
            maintenance: "Maintenance (monthly fee)",
            extras: "Other extras",
            totalCosts: "Total Costs",
            totalCostsTax: "Tax Costs",
            totalInvoice: "Total to invoice",
            ownerNetProfit: "Net owner",
            ownerNetProfitNoVAT: "Net owner (without VAT)",
            irpf: "IRPF",
            bills: "Bills",
            month: "Month",
            rent: "Rent",
            exportExcel: "Export Excel",
            type: "Stay Type",
            shortTerm: "Touristic Stay",
            midTerm: "Monthly stays",
            noData: "No data",
            comment: "Your comment",
            approveReport: "Approve",
            rejectReport: "Reject",
            send: "Send",
            rejectReportComment: "Leave your comment here. Owners Department will contact you as soon as they receives it",
            reportApproved: "Thank you for approving the settlement. Owners Department will contact you shortly to send the invoice and make a transfer",
            chargedCompany: "Charged by Stay U-nique",
            chargedOwner: "Charged by owner",
            toTransfer: "To transfer to owner",
            toTransferSU: "To transfer to Stay U-nique",
            totalRevenue: "Total Revenue",
            totalCommisionChannel: "Total Commission Channel",
            netRevenue: "Base"
        },
        documents: {
            name: "Documents",
            fields: {
                propertyId: "Property",
                fileName: "Name",
                fileURL: "Download"
            },
            download: "Download File"
        }
    },
    misc: {
        fetchError: "Error fetching data"
    }
};

export default messages;
