/* eslint-disable eqeqeq */
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { useDataProvider, Loading, Error, Authenticated, Title, useTranslate, useGetIdentity, useLocale, useQuery, usePermissions } from "react-admin";
import { Card, CardContent, FormControl, Button, makeStyles, Select, InputLabel, MenuItem, CircularProgress, Table as MUITable, TableBody, TableCell, TableRow, TableHead } from "@material-ui/core";
import { Grid as MUIGrid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import moment from "moment";
import { TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import { Grid, Table, TableHeaderRow, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import saveAs from "file-saver";
import styled from "styled-components";

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);

const monthOptions = ({ locale }) => {
    moment.locale(locale);

    let options = [];

    const dateStart = moment().startOf("year").subtract(2, "year");
    const endDate = moment().subtract(1, "month");

    while (dateStart.isSameOrBefore(endDate)) {
        const monthName = dateStart.format("MMMM YYYY");
        const month = dateStart.format("MM");
        const year = dateStart.format("YYYY");

        options.push({ id: `${month}-${year}`, name: monthName });

        dateStart.add(1, "month");
    }

    return options.reverse();
};

const monthOptionsFuture = ({ locale }) => {
    moment.locale(locale);

    let options = [];

    const dateStart = moment().startOf("year");
    const endDate = moment().subtract(1, "month").endOf("year");

    while (dateStart.isSameOrBefore(endDate)) {
        const monthName = dateStart.format("MMMM YYYY");
        const month = dateStart.format("MM");
        const year = dateStart.format("YYYY");

        options.push({ id: `${month}-${year}`, name: monthName });

        dateStart.add(1, "month");
    }

    return options.reverse();
};

const CellWithoutData = styled.td`
    text-align: center;
`;

const StyledTableCell = styled(TableCell)`
    white-space: nowrap;
    overflow: hidden;
    padding-left: 8px;
    padding-right: 8px;
    text-overflow: ellipsis;
    color: red;
`;

function noDataCell(props, translate) {
    return <CellWithoutData colSpan={props.colSpan}>{translate("resources.reports.noData")}</CellWithoutData>;
}

const ReportsWrapper = styled.div`
    width: 90vw;
    @media (max-width: 768px) {
        width: 95vw;
    }
`;

const MoneyTable = styled(MUITable)`
    margin-top: 50px;
    width: 400px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        marginRight: 5,
        alignItems: "center"
    },
    wrapper: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
    button: {
        marginLeft: 10
    },
    customHeaderCell: {
        "& div": {
            whiteSpace: "normal",
            wordWrap: "break-word"
        }
    }
}));

const CustomTableHeaderCell = ({ ...restProps }) => {
    restProps.value = restProps.column.title || restProps.column.name;

    const classes = useStyles();
    return <TableHeaderRow.Cell className={classes.customHeaderCell} {...restProps} />;
};

const Reports = ({ location }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const locale = useLocale();
    const { permissions } = usePermissions();
    const [ownerInvoices, setOwnerInvoices] = useState(0);

    const { identity, loading: identityLoading } = useGetIdentity();

    // set cols
    useEffect(() => {
        if (identity) {
            setOwnerInvoices(identity.ownerInvoices);
        }
    }, [identity]);

    const columnsStayInvoiceOwner = useMemo(
        () => [
            { name: "label", title: translate("resources.reports.label"), width: 400 },
            { name: "from", title: translate("resources.reports.from"), width: 100 },
            { name: "to", title: translate("resources.reports.to"), width: 100 },
            { name: "nightsBookingInMonth", title: translate("resources.reports.nights"), width: 100 },
            { name: "people", title: translate("resources.reports.people"), width: 100 },
            { name: "revenue", title: translate("resources.reports.revenue"), width: 100 },
            { name: "channel", title: translate("resources.reports.channel"), width: 100 },
            { name: "commissionCompany", title: translate("resources.reports.commissionCompany"), width: 100 },
            { name: "channelCommission", title: translate("resources.reports.channelCommission"), width: 100 },
            { name: "cleaning", title: translate("resources.reports.cleaning"), width: 100 },
            { name: "laundry", title: translate("resources.reports.laundry"), width: 100 },
            { name: "maintenance", title: translate("resources.reports.maintenance"), width: 100 },
            { name: "extras", title: translate("resources.reports.extras"), width: 100 }
            // { name: "totalCosts", title: translate("resources.reports.totalCosts"), width: 100 },
            // { name: "totalCostsTax", title: translate("resources.reports.totalCostsTax"), width: 100 },
            // { name: "totalInvoice", title: translate("resources.reports.totalInvoice") },
            // { name: "ownerNetProfit", title: translate("resources.reports.ownerNetProfit") }
        ],
        [translate]
    );

    const columnsOwnerInvoicesSU = useMemo(
        () => [
            { name: "label", title: translate("resources.reports.label"), width: 400 },
            { name: "from", title: translate("resources.reports.from"), width: 100 },
            { name: "to", title: translate("resources.reports.to"), width: 100 },
            { name: "nightsBookingInMonth", title: translate("resources.reports.nights"), width: 100 },
            { name: "people", title: translate("resources.reports.people"), width: 100 },
            { name: "revenue", title: translate("resources.reports.revenue"), width: 100 },
            { name: "netRevenue", title: translate("resources.reports.netRevenue"), width: 100 },
            { name: "channel", title: translate("resources.reports.channel"), width: 100 },
            { name: "channelCommission", title: translate("resources.reports.channelCommission"), width: 100 },
            { name: "commissionCompany", title: translate("resources.reports.commissionCompany"), width: 100 },
            { name: "cleaning", title: translate("resources.reports.cleaning"), width: 100 },
            { name: "laundry", title: translate("resources.reports.laundry"), width: 100 },
            { name: "maintenance", title: translate("resources.reports.maintenance"), width: 100 },
            { name: "extras", title: translate("resources.reports.extras"), width: 100 },
            // { name: "irpf", title: translate("resources.reports.irpf"), width: 100 },
            { name: "ownerNetProfit", title: translate("resources.reports.ownerNetProfitNoVAT") }
        ],
        [translate]
    );

    const columnsMidTerm = useMemo(
        () => [
            { name: "label", title: translate("resources.reports.label"), width: 400 },
            { name: "from", title: translate("resources.reports.from"), width: 100 },
            { name: "to", title: translate("resources.reports.to"), width: 100 },
            { name: "nightsBookingInMonth", title: translate("resources.reports.nights"), width: 100 },
            { name: "people", title: translate("resources.reports.people"), width: 100 },
            { name: "revenue", title: translate("resources.reports.rent"), width: 100 },
            { name: "bills", title: translate("resources.reports.bills"), width: 100 },
            { name: "commissionCompany", title: translate("resources.reports.commissionCompany"), width: 100 },
            { name: "channelCommission", title: translate("resources.reports.channelCommission"), width: 100 },
            { name: "maintenance", title: translate("resources.reports.maintenance"), width: 100 },
            { name: "extras", title: translate("resources.reports.extras"), width: 100 },
            { name: "ownerNetProfit", title: translate("resources.reports.ownerNetProfit") }
        ],
        [translate]
    );

    const month = moment().subtract(1, "month").format("MM");
    const year = moment().format("YYYY");

    const [columns, setColumns] = useState(columnsOwnerInvoicesSU);
    const [report, setReportData] = useState([]);

    const [currentMonth, setCurrentMonth] = useState(`${month}-${year}`);
    const [currentView, setCurrentView] = useState(0);

    const [tableColumnExtensions] = useState(columns.map(column => ({ columnName: column.name, width: column.width })));
    const [exportLoading, setExportLoading] = useState(false);

    const [approvedDialogOpen, setapprovedDialogOpen] = React.useState(false);

    const [totals, setTotals] = useState({ toTransfer: 0, chargedCompany: 0, chargedOwner: 0 });
    const [invoice, setInvoice] = useState([]);
    const [reportFinalized, setReportFinalized] = useState([]);
    const [reportApproved, setReportApproved] = useState([]);

    const dataProvider = useDataProvider();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const { data, loading, error } = useQuery({
        type: "get",
        resource: "reports",
        payload: {
            type: currentView,
            year: currentMonth.split("-").pop(),
            month: currentMonth.split("-").shift()
        }
    });

    const handleCloseApprovedDialog = () => {
        setapprovedDialogOpen(false);
    };

    const doExport = val => {
        setExportLoading(true);

        const [month, year] = currentMonth.split("-");

        dataProvider
            .post("generateReport", { data: { month, year, type: currentView } })
            .then(({ data }) => {
                const { urlReport } = data;
                saveAs(urlReport, urlReport.split(".com/").pop());
                setExportLoading(false);
            })
            .catch(error => {
                setExportLoading(false);
            });
    };

    const approveReport = () => {
        const [month, year] = currentMonth.split("-");

        dataProvider
            .patch("reportStatus", { data: { month, year, status: 1 } })
            .then(({ data }) => {
                if (data.error) {
                    console.log(data.error);
                }
                setapprovedDialogOpen(true);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (currentView === 1) {
            setColumns(columnsMidTerm);
        } else {
            setColumns(ownerInvoices ? columnsOwnerInvoicesSU : columnsStayInvoiceOwner);
        }

        if (data && data.length > 0) {
            let newData = [];
            let totals = { toTransfer: 0, chargedCompany: 0, chargedOwner: 0 };

            data.forEach(element => {
                if (currentView === 1) {
                    element.commissionCompany = parseFloat(element.commissionCompany * 1).toFixed(2);
                }

                if (element.label !== "Total") {
                    totals.toTransfer += parseFloat(element.toTransfer);
                    totals.chargedCompany += parseFloat(element.chargedCompany);
                    totals.chargedOwner += parseFloat(element.chargedOwner);
                } else {
                    setInvoice(element.invoice);
                    setReportFinalized(element.reportFinalized);
                    setReportApproved(element.reportApproved);
                }

                newData.push(element);
            });

            setTotals(totals);
            setReportData(newData);
        } else {
            setTotals({ toTransfer: 0, chargedCompany: 0, chargedOwner: 0 });
            setReportData([]);
        }
    }, [currentMonth, currentView, columnsOwnerInvoicesSU, columnsMidTerm, columnsStayInvoiceOwner, ownerInvoices, data]);

    if (loading || identityLoading) return <Loading />;
    if (error) return <Error error="misc.fetchError" />;
    if (!data) return null;

    return (
        <Authenticated location={location}>
            <Card>
                <Title title={translate("resources.reports.name")} />
                <CardContent>
                    <MUIGrid container spacing={2} alignItems="center" alignContent="center">
                        <MUIGrid item md={3} xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="property">{translate("resources.reports.month")}</InputLabel>
                                <Select
                                    value={currentMonth}
                                    onChange={event => setCurrentMonth(event.target.value)}
                                    inputProps={{
                                        name: "month",
                                        id: "month"
                                    }}
                                >
                                    {permissions === "variableTemp" &&
                                        monthOptionsFuture({ locale }).map(month => {
                                            return (
                                                <MenuItem key={month.id} value={month.id}>
                                                    {month.name}
                                                </MenuItem>
                                            );
                                        })}
                                    {permissions !== "variableTemp" &&
                                        monthOptions({ locale }).map(month => {
                                            return (
                                                <MenuItem key={month.id} value={month.id}>
                                                    {month.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </MUIGrid>
                        <MUIGrid item md={3} xs={12}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="property">{translate("resources.reports.type")}</InputLabel>
                                <Select
                                    value={currentView}
                                    onChange={event => setCurrentView(event.target.value)}
                                    inputProps={{
                                        name: "view",
                                        id: "view"
                                    }}
                                >
                                    <MenuItem value={0}>{translate("resources.reports.shortTerm")}</MenuItem>
                                    <MenuItem value={1}>{translate("resources.reports.midTerm")}</MenuItem>
                                </Select>
                            </FormControl>
                        </MUIGrid>
                        <MUIGrid item md={6} xs={12}>
                            <div className={classes.root}>
                                <div className={classes.wrapper}>
                                    <Button variant="contained" onClick={doExport} color="primary" disabled={exportLoading}>
                                        {translate("resources.reports.exportExcel")}
                                    </Button>
                                    {exportLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                                {reportFinalized == 1 && reportApproved == 0 && (
                                    <Button variant="contained" onClick={approveReport} color="primary" className={classes.button} disabled={exportLoading}>
                                        {translate("resources.reports.approveReport")}
                                    </Button>
                                )}
                            </div>
                        </MUIGrid>
                    </MUIGrid>

                    <ReportsWrapper>
                        <Grid rows={report} columns={columns}>
                            <TreeDataState />
                            <CustomTreeData getChildRows={getChildRows} />
                            <Table
                                columnExtensions={tableColumnExtensions}
                                noDataCellComponent={props => noDataCell(props, translate)}
                                cellComponent={props => {
                                    const { column, row, value } = props;
                                    if (row.averaged) {
                                        return <StyledTableCell>{column.name === "nightsBookingInMonth" ? `(${row.nights}) ${row.nightsBookingInMonth}` : value}</StyledTableCell>;
                                    }
                                    if (row.label === "Total") {
                                        return <Table.Cell>{value}</Table.Cell>;
                                    }

                                    return <Table.Cell {...props} />;
                                }}
                            />

                            <TableHeaderRow cellComponent={CustomTableHeaderCell} />

                            <TableTreeColumn for="label" />
                        </Grid>
                    </ReportsWrapper>

                    {reportFinalized == 1 && (
                        <MoneyTable size="small">
                            <TableHead>
                                <h3>Factura</h3>
                            </TableHead>
                            <TableBody>
                                {invoice.map(invoiceitem => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {invoiceitem[0]}
                                        </TableCell>
                                        <TableCell align="right">€{invoiceitem[1].toFixed(2)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </MoneyTable>
                    )}

                    <MoneyTable size="small">
                        <TableHead>
                            <h3>Pagos</h3>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {translate("resources.reports.chargedCompany")}
                                </TableCell>
                                <TableCell align="right">€{totals.chargedCompany.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {translate("resources.reports.chargedOwner")}
                                </TableCell>
                                <TableCell align="right">€{totals.chargedOwner.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {totals.toTransfer >= 0 ? translate("resources.reports.toTransferSU") : translate("resources.reports.toTransfer")}
                                </TableCell>
                                <TableCell align="right">€{totals.toTransfer < 0 ? totals.toTransfer.toFixed(2).substring(1) : totals.toTransfer.toFixed(2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </MoneyTable>
                </CardContent>

                <Dialog open={approvedDialogOpen} fullScreen={fullScreen} onClose={handleCloseApprovedDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate("resources.reports.approveReport")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{translate("resources.reports.reportApproved")}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseApprovedDialog} color="primary">
                            {translate("ra.action.close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </Authenticated>
    );
};

export default Reports;
