import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import customRoutes from "./customRoutes";
import reviews from "./reviews";
import bookings from "./bookings";
import properties from "./properties";
import maintenance from "./maintenance";
import documents from "./documents";
import items from "./items";
import themeReducer from "./themeReducer";
import { Layout, Menu } from "./layout";
import { Dashboard } from "./dashboard";
import Login from "./layout/Login";

import en from "./i18n/en";
import es from "./i18n/es";
import ca from "./i18n/ca";
import polyglotI18nProvider from "ra-i18n-polyglot";

import dataProvider from "./dataProvider";

let lang = localStorage.getItem("odLang");
if (!lang) {
    lang = "es";
}

const messages = {
    es,
    ca,
    en
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], lang);

class App extends Component {
    render() {
        return (
            <Admin
                loginPage={Login}
                dashboard={Dashboard}
                layout={Layout}
                customReducers={{ theme: themeReducer }}
                menu={Menu}
                i18nProvider={i18nProvider}
                customRoutes={customRoutes}
                title="Stay U-nique"
                authProvider={authProvider}
                dataProvider={dataProvider}
            >
                {permissions => getAccessibleViews(permissions)}
            </Admin>
        );
    }
}

function getAccessibleViews(permissions) {
    let resources = [];

    if (["variable", "variableTemp"].includes(permissions)) {
        resources.push(<Resource show name="bookings" {...bookings} />);
        resources.push(<Resource show name="properties" {...properties} />);
        resources.push(<Resource show name="reviews" {...reviews} />);
        resources.push(<Resource show name="maintenance" {...maintenance} />);
        resources.push(<Resource name="channels" />);
        resources.push(<Resource show name="items" {...items} />);
    } else if (permissions === "fixed") {
        resources.push(<Resource show name="bookings" {...bookings} />);
        resources.push(<Resource show name="properties" {...properties} />);
        resources.push(<Resource show name="reviews" {...reviews} />);
        resources.push(<Resource name="channels" />);
        resources.push(<Resource show name="maintenance" {...maintenance} />);
        resources.push(<Resource show name="items" {...items} />);
    }

    resources.push(<Resource show name="documents" {...documents} />);

    return resources;
}

export default App;
