import spanishMessages from "@blackbox-vision/ra-language-spanish";

const messages = {
    ...spanishMessages,
    auth: {
        reset: "¿Has olvidado la contraseña?",
        sent_reset: "Enviar contraseña",
        reset_error: "Error al enviar contraseña",
        reset_success: "Nueva contraseña enviada a su correo electrónico"
    },
    resources: {
        settings: {
            name: "Configuración",
            language: "Idioma",
            theme: {
                name: "Tema",
                light: "Luz",
                dark: "Oscuro"
            }
        },
        dashboard: {
            name: "Dashboard",
            revenue: "Ingresos",
            num_bookings: "Reservas",
            num_properties: "Propiedades",
            rating: "Valoraciones",
            welcome: {
                title: "Bienvenido/a",
                subtitle: "Este es tu panel de control personalizado donde puedes ver información relacionada con tu/s propiedad/es."
            },
            charts: {
                occupancy: "Ocupación (%)",
                adr: "Precio medio",
                revenue: "Ingresos",
                currentYear: "Año actual",
                previousYear: "Año pasado"
            }
        },
        bookings: {
            name: "Reserva |||| Reservas",
            fields: {
                createdDate: "Creado",
                arrivalDate: "Llegada",
                arrivalTime: "Hora de llegada",
                departureDate: "Salida",
                departureTime: "Hora de salida",
                totalPrice: "Total pagado por cliente",
                propertyId: "Propiedad",
                numPax: "Pax",
                numNights: "Noches",
                bookingChannel: "Canal",
                month: "Mes"
            }
        },
        reviews: {
            name: "Valoracion |||| Valoraciones",
            fields: {
                reviewDate: "Fecha",
                propertyId: "Propiedad",
                comment: "Comentario",
                commentPrivate: "Comentario Privado",
                rating: "Valoración",
                bookingChannel: "Canal",
                reviewSource: "Canal de Valoración",
                sourceName: "Canal de Valoración",
                reviewDateRange: "Rango de fechas",
                month: "Mes"
            }
        },
        properties: {
            name: "Propiedad |||| Propiedades",
            fields: {
                propertyName: "Propiedad",
                propertyCapacity: "Capacidad",
                propertyNumBedrooms: "Habitaciones",
                propertyNumBathrooms: "Baños",
                propertyAverage: "Valoración general",
                createdDate: "Creado",
                propertyLicense: "Número de licencia",
                propertyCadastral: "Num. cadastrál",
                propertySize: "Tamaño (m2)",
                propertyAddress: "Dirección",
                propertyChannel: "Canales",
                propertyPhotos: "Fotos"
            },
            reportIssue: "Informar problema",
            reportIssueComment: "Tu comentario",
            reportIssueDesc: "Informe cualquier imprecisión, error o agregue cualquier comentario",
            feedbackSent: "Comentarios enviados",
            feedbackError: "Error al enviar comentarios"
        },
        calendar: { name: "Calendario", properties: "Propiedades", occupancy: "Ocupación" },
        maintenance: {
            name: "Mantenimiento",
            fields: {
                propertyId: "Propiedad",
                taskDesc: "Tarea",
                taskCreation: "Creado",
                month: "Mes",
                taskCost: "Coste de reparación/material",
                taskCreationRangeOptions: { currentMonth: "Mes actual" },
                taskPaidBy: "Pagado por"
            }
        },
        items: {
            name: "Menaje de hogar",
            fields: {
                propertyId: "Propiedad",
                productName: "Producto",
                productQuantity: "Cantidad",
                productCost: "Coste",
                dateOut: "Fecha",
                month: "Mes",
                storagePaidBy: "Pagado por"
            },
            amountTotal: "Cantidad total:",
            costTotal: "Coste total:"
        },
        user: {
            name: "Usuario",
            save: "Guardar cambios",
            email: "Email",
            saveSuccess: "Cambios guardados",
            saveError: "Error al guardar los cambios"
        },
        utilities: {
            name: "Suministros",
            property: "Propiedad",
            gas: "Gas Natural",
            provider_name: "Nombre del proveedor",
            title_name: "Nombre del título",
            cif: "CIF",
            cups: "CUPS",
            electricity: "Luz",
            water: "Agua",
            internet: "Internet",
            insurance: "Seguro",
            line_number: "Número de línea",
            policy_number: "Número de póliza",
            request_change: "Solicitar cambio",
            saveSuccess: "Solicitud enviada",
            saveError: "Error al enviar equest",
            select_property: "Seleccione una propiedad"
        },
        reports: {
            name: "Informes",
            label: "Nombre",
            from: "Desde",
            to: "Hasta",
            nights: "Noches",
            people: "Personas",
            channel: "Plataforma",
            revenue: "Ingresos",
            commissionCompany: "Comisión Stay U-nique",
            channelCommission: "Comisión Plataforma",
            cleaning: "Limpieza",
            laundry: "Lavandería",
            maintenance: "Mantenimiento",
            extras: "Otros extras",
            totalCosts: "Total Gastos",
            totalCostsTax: "IVA Gastos",
            totalInvoice: "Total a facturar",
            ownerNetProfit: "Neto Propietario",
            ownerNetProfitNoVAT: "Neto Propietario (sin IVA)",
            irpf: "IRPF",
            bills: "Suministros",
            month: "Mes",
            rent: "Alquiler",
            exportExcel: "Exportar Excel",
            type: "Tipo estancia",
            shortTerm: "Estancias turísticas",
            midTerm: "Estancias mensuales",
            noData: "Sin datos",
            comment: "Su comentario",
            approveReport: "Aprobar",
            rejectReport: "No aprobar",
            send: "Enviar",
            rejectReportComment: "Deja tu comentario aquí. El Departamento de Propietarios se pondrá en contacto contigo en cuanto lo reciba",
            reportApproved: "Gracias por aprobar la liquidación. En breve el Departamento de Propietarios te contactará para enviar la factura y hacer transferencia",
            chargedCompany: "Cobrado por Stay U-nique",
            chargedOwner: "Cobrado por propietario",
            toTransfer: "A transferir a propietario",
            toTransferSU: "A transferir a Stay U-nique",
            netRevenue: "Base Imponible"
        },
        documents: {
            name: "Documentos",
            fields: {
                propertyId: "Propiedad",
                fileName: "Nombre de archivo",
                fileURL: "Descargar"
            },
            download: "Descargar archivo"
        }
    },
    misc: {
        fetchError: "Error al obtener datos"
    }
};

export default messages;
