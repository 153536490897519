import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslate } from "react-admin";

const Welcome = ({ name }) => {
    const translate = useTranslate();

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">{name ? translate("resources.dashboard.welcome.title") + " " + name : <CircularProgress size={20} />}</Typography>
                <Typography component="p">{translate("resources.dashboard.welcome.subtitle")}</Typography>
            </CardContent>
        </Card>
    );
};

export default Welcome;
