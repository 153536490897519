import React from "react";
import { connect } from "react-redux";
import { MenuItemLink, getResources, Responsive, useTranslate, usePermissions } from "react-admin";
import { withRouter } from "react-router-dom";
import Dashboard from "@material-ui/icons/Dashboard";
import CalendarToday from "@material-ui/icons/Today";
import Settings from "@material-ui/icons/Settings";
import User from "@material-ui/icons/Person";
import Utilities from "@material-ui/icons/FlashOn";
import Reports from "@material-ui/icons/Equalizer";

const MyMenu = ({ resources, onMenuClick, logout }) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    return (
        <div>
            <MenuItemLink leftIcon={<Dashboard />} to="/" primaryText={translate("resources.dashboard.name")} onClick={onMenuClick} />
            {resources.map(resource => {
                if (resource.hasList) {
                    return (
                        <MenuItemLink
                            leftIcon={<resource.icon />}
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={translate(`resources.${resource.name}.name`, { smart_count: 2 })}
                            onClick={onMenuClick}
                        />
                    );
                }
                return null;
            })}
            <MenuItemLink leftIcon={<User />} to="/user" primaryText={translate("resources.user.name")} onClick={onMenuClick} />
            <MenuItemLink leftIcon={<Utilities />} to="/utilities" primaryText={translate("resources.utilities.name")} onClick={onMenuClick} />
            <MenuItemLink leftIcon={<Reports />} to="/reports" primaryText={translate("resources.reports.name")} onClick={onMenuClick} />
            {["variable", "variableTemp"].includes(permissions) && (
                <>
                    <MenuItemLink leftIcon={<CalendarToday />} to="/calendar" primaryText={translate("resources.calendar.name")} onClick={onMenuClick} />
                </>
            )}
            <MenuItemLink leftIcon={<Settings />} to="/settings" primaryText={translate("resources.settings.name")} onClick={onMenuClick} />
            <Responsive small={logout} medium={null} />
        </div>
    );
};

const mapStateToProps = state => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(MyMenu));
