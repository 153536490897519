import React from "react";
import Card from "@material-ui/core/Card";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import CardIcon from "./CardIcon";

const useStyles = makeStyles({
    main: {
        flex: "1",
        marginTop: 20
    },
    card: {
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
        minHeight: 52
    },
    title: {}
});

const NumBooking = ({ value, style }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.main} style={{ style }}>
            <CardIcon Icon={SupervisorAccount} bgColor={theme.palette.primary.main} />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {translate("resources.dashboard.num_bookings")}
                </Typography>
                <Typography variant="h6">{value}</Typography>
            </Card>
        </div>
    );
};

export default NumBooking;
