import React from "react";
import { connect } from "react-redux";
import { Layout, Sidebar } from "react-admin";
import AppBar from "./AppBar";
import { darkTheme, lightTheme } from "./themes";

const CustomSidebar = props => <Sidebar {...props} />;
const CustomLayout = props => <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />;

const themeMap = {
    dark: darkTheme,
    light: lightTheme
};
export default connect(
    state => ({
        theme: themeMap[state.theme]
    }),
    {}
)(CustomLayout);
