import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslate, useLocale, useSetLocale, Title, Authenticated } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { changeTheme } from "./actions";

import { version } from "../../package.json";

const useStyles = makeStyles({
    formControl: {
        marginBottom: 20,
        minWidth: 250
    }
});

const Settings = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles();
    const theme = useSelector(state => state.theme);
    const dispatch = useDispatch();

    return (
        <Authenticated>
            <Card>
                <Title title={translate("resources.settings.name")} />
                <CardContent>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="theme">{translate("resources.settings.theme.name")}</InputLabel>
                        <Select
                            value={theme}
                            onChange={event => {
                                dispatch(changeTheme(event.target.value));
                            }}
                            inputProps={{
                                name: "theme",
                                id: "theme"
                            }}
                        >
                            <MenuItem value={"light"}>{translate("resources.settings.theme.light")}</MenuItem>
                            <MenuItem value={"dark"}>{translate("resources.settings.theme.dark")}</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="lang">{translate("resources.settings.language")}</InputLabel>
                        <Select
                            value={locale}
                            onChange={event => {
                                setLocale(event.target.value);
                                localStorage.setItem("odLang", event.target.value);
                            }}
                            inputProps={{
                                name: "lang",
                                id: "lang"
                            }}
                        >
                            <MenuItem value={"es"}>Spanish</MenuItem>
                            <MenuItem value={"ca"}>Catalan</MenuItem>
                            <MenuItem value={"en"}>English</MenuItem>
                        </Select>
                    </FormControl>

                    <Typography color="textSecondary">{version}</Typography>
                </CardContent>
            </Card>
        </Authenticated>
    );
};

export default Settings;
