import React from "react";
import { List, Datagrid, TextField, Filter, DateField, ReferenceInput, SelectInput, ReferenceField, useLocale } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";
import { monthOptions } from "../utils/utils";

const styles = {
    headerRow: {
        borderLeftColor: "white",
        borderLeftWidth: 5,
        borderLeftStyle: "solid"
    },
    comment: {
        width: 250,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
};

const filterStyles = {
    status: { width: 150 }
};

const currentMonth = moment().format("MM");
const currentYear = moment().year();
const defaultValue = `${currentMonth}-${currentYear}`;

const EuroField = ({ record = {} }) => {
    if (record.taskCost === null || record.taskCost === 0) {
        return null;
    }

    return <span>€{record.taskCost}</span>;
};

const MaintenanceFilter = withStyles(filterStyles)(({ classes, ...props }) => {
    const locale = useLocale();

    return (
        <Filter {...props}>
            <SelectInput source="month" choices={monthOptions({ locale })} alwaysOn allowEmpty={false} />

            <ReferenceInput source="propertyId" reference="properties">
                <SelectInput optionText={choice => `${choice.propertyName}`} />
            </ReferenceInput>
        </Filter>
    );
});

const MaintenanceList = ({ classes, ...props }) => {
    return (
        <List {...props} bulkActionButtons={false} filters={<MaintenanceFilter />} sort={{ field: "taskCreation", order: "DESC" }} filterDefaultValues={{ month: defaultValue }}>
            <Datagrid>
                <DateField locales="en-GB" source="taskCreation" />

                <ReferenceField source="propertyId" reference="properties" link="show">
                    <TextField source="propertyName" />
                </ReferenceField>

                <TextField source="taskDesc" />
                <EuroField source="taskCost" />
                <TextField source="taskPaidBy" />
            </Datagrid>
        </List>
    );
};
export default withStyles(styles)(MaintenanceList);
