import decode from "jwt-decode";

const authProvider = {
    login: async ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_API_URL + "/od/login", {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({ "Content-Type": "application/json" })
        });
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            throw new Error("ra.auth.sign_in_error");
        }
        const { token } = await response.json();
        localStorage.setItem("odToken", token);
    },
    loginFromToken: async ({ token }) => {
        localStorage.setItem("odToken", token);

        return Promise.resolve();
    },
    reset: async ({ email }) => {
        const request = new Request(process.env.REACT_APP_API_URL + "/od/reset-password", {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: new Headers({ "Content-Type": "application/json" })
        });
        const response = await fetch(request);
        if (response.status < 200 || response.status >= 300) {
            throw new Error("auth.reset_error");
        }
        return await response.json();
    },
    logout: () => {
        localStorage.removeItem("odToken");
        return Promise.resolve();
    },
    checkAuth: () => (localStorage.getItem("odToken") ? Promise.resolve() : Promise.reject()),
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("odToken");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const token = localStorage.getItem("odToken");

        try {
            const { userType } = decode(token);

            let userTypeName = "variable";
            if (userType === 2) {
                userTypeName = "fixed";
            } else if (userType === 3) {
                userTypeName = "variableTemp";
            }

            return Promise.resolve(userTypeName);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getIdentity: () => {
        const token = localStorage.getItem("odToken");

        try {
            const { userId, userName, ownerInvoices, userType, netHasVat } = decode(token);
            return Promise.resolve({ id: userId, fullName: userName, avatar: "", ownerInvoices, userType, netHasVat });
        } catch (error) {
            return Promise.reject(error);
        }
    }
};

export default authProvider;
