import React from "react";
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, ReferenceField, DateField, useTranslate, useLocale, ShowButton } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";
import { monthOptions } from "../utils/utils";

import StarRatingField from "./StarRatingField";

const styles = {
    headerRow: {
        borderLeftColor: "white",
        borderLeftWidth: 5,
        borderLeftStyle: "solid"
    },
    comment: {
        width: 250,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
};

const filterStyles = {
    status: { width: 150 }
};

const currentMonth = moment().format("MM");
const currentYear = moment().year();
const defaultValue = `${currentMonth}-${currentYear}`;

const ReviewFilter = withStyles(filterStyles)(({ classes, ...props }) => {
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <Filter {...props}>
            <SelectInput source="month" choices={monthOptions({ locale })} alwaysOn allowEmpty={false} />

            <ReferenceInput source="propertyId" reference="properties">
                <SelectInput optionText={choice => `${choice.propertyName}`} />
            </ReferenceInput>

            <ReferenceInput source="bookingChannelId" reference="channels" label={translate("resources.reviews.fields.bookingChannel")}>
                <SelectInput optionText={choice => `${choice.bookingChannel}`} />
            </ReferenceInput>

            <SelectInput
                source="reviewSource"
                choices={[
                    { id: 1, name: "Manual" },
                    { id: 2, name: "Booking.com" },
                    { id: 3, name: "Stay U-nique" },
                    { id: 4, name: "Airbnb" }
                ]}
            />
        </Filter>
    );
});

const ReviewList = ({ classes, ...props }) => {
    return (
        <List {...props} bulkActionButtons={false} filters={<ReviewFilter />} sort={{ field: "reviewDate", order: "DESC" }} filterDefaultValues={{ month: defaultValue }}>
            <Datagrid>
                <ReferenceField source="propertyId" reference="properties" link="show">
                    <TextField source="propertyName" />
                </ReferenceField>
                <DateField locales="en-GB" source="reviewDate" />
                <TextField source="bookingChannel" />
                <TextField source="sourceName" />

                <StarRatingField />

                <ShowButton />
            </Datagrid>
        </List>
    );
};

export default withStyles(styles)(ReviewList);
