import ReviewList from "./ReviewList";
import ReviewShow from "./ReviewShow";
import StarIcon from "@material-ui/icons/Star";

const Reviews = {
    list: ReviewList,
    show: ReviewShow,
    icon: StarIcon
};

export default Reviews;