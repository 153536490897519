import React from "react";
import { TextField, Show, SimpleShowLayout } from "react-admin";

const ReviewTitle = ({ record }) => {
    return <span>{record ? `${record.propertyName}` : ""}</span>;
};

const ReviewShow = props => (
    <Show {...props} title={<ReviewTitle />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="comment" />
            <TextField source="commentPrivate" />
            <TextField source="bookingChannel" />
        </SimpleShowLayout>
    </Show>
);

export default ReviewShow;
