import { fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import queryString from "query-string";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("odToken");

    options.headers.set("Authorization", `Bearer ${token}`);

    return fetchUtils.fetchJson(url, options);
};

const apiUrl = process.env.REACT_APP_API_URL + "/od";
const restProvider = simpleRestProvider(apiUrl, httpClient);

const myDataProvider = {
    ...restProvider,
    get: (resource, params) => {
        let url = `${apiUrl}/${resource}`;

        const query = queryString.stringify(params);
        if (query && query.length > 0) {
            url += `?${query}`;
        }

        return httpClient(url).then(({ json }) => ({
            data: json
        }));
    },
    patch: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "PATCH",
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json })),
    post: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }))
};

const dataProvider = new Proxy(myDataProvider, {
    get: (target, name, self) =>
        name === "then" // as we await for the dataProvider, JS calls then on it. We must trap that call or else the dataProvider will be called with the then method
            ? self
            : (resource, params) => new Promise(resolve => setTimeout(() => resolve(myDataProvider[name](resource, params)), 500))
});

export default dataProvider;
