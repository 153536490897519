import React from "react";

const StarRatingField = ({ record }) => <span>{Math.round(record.rating, 2)}/10</span>;

StarRatingField.defaultProps = {
    label: "resources.reviews.fields.rating",
    source: "rating",
    addLabel: true
};

export default StarRatingField;
