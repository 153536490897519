import React from "react";
import { AppBar, UserMenu, MenuItemLink, useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from "@material-ui/icons/Settings";
import UserIcon from "@material-ui/icons/Person";
import { withStyles } from "@material-ui/core/styles";

import Logo from "./Logo";

const styles = {
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    spacer: {
        flex: 1
    }
};

const CustomUserMenu = ({ ...props }) => {
    const translate = useTranslate();

    return (
        <UserMenu {...props}>
            <MenuItemLink to="/settings" primaryText={translate("resources.settings.name")} leftIcon={<SettingsIcon />} />
            <MenuItemLink to="/user" primaryText={translate("resources.user.name")} leftIcon={<UserIcon />} />
        </UserMenu>
    );
};

const CustomAppBar = ({ classes, ...props }) => (
    <AppBar {...props} userMenu={<CustomUserMenu />} color="primary">
        <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
        <Logo />
        <span className={classes.spacer} />
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);
