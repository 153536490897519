import moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";

export function monthOptions({ locale }) {
    moment.locale(locale);

    let options = [];

    const dateStart = moment().startOf("year").subtract(1, "year");
    const endDate = moment().endOf("year");

    while (dateStart.isSameOrBefore(endDate)) {
        const monthName = dateStart.format("MMMM YYYY");
        const month = dateStart.format("MM");
        const year = dateStart.format("YYYY");

        options.push({ id: `${month}-${year}`, name: monthName });

        dateStart.add(1, "month");
    }

    return options.reverse();
}

export function monthOptionsPlusYear({ locale }) {
    moment.locale(locale);

    let options = [];

    const dateStart = moment().startOf("year").subtract(1, "year");
    const endDate = moment().endOf("year").add(1, "year");

    while (dateStart.isSameOrBefore(endDate)) {
        const monthName = dateStart.format("MMMM YYYY");
        const month = dateStart.format("MM");
        const year = dateStart.format("YYYY");

        options.push({ id: `${month}-${year}`, name: monthName });

        dateStart.add(1, "month");
    }

    return options.reverse();
}
