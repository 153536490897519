import React from "react";
import Card from "@material-ui/core/Card";
import EuroIcon from "@material-ui/icons/EuroSymbol";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import CardIcon from "./CardIcon";

const useStyles = makeStyles({
    main: {
        flex: "1",
        marginRight: "1em",
        marginTop: 20
    },
    card: {
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
        minHeight: 52
    },
    title: {}
});

const Revenue = ({ value }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <CardIcon Icon={EuroIcon} bgColor={theme.palette.secondary.main} />
            <Card className={classes.card}>
                <Typography className={classes.title} color="textSecondary">
                    {translate("resources.dashboard.revenue")}
                </Typography>
                <Typography variant="h6">{value}</Typography>
            </Card>
        </div>
    );
};

export default Revenue;
