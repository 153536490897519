import React from "react";
import { List, Datagrid, TextField, Filter, DateField, NumberInput, DateInput, ReferenceInput, SelectInput, ReferenceField, useLocale } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";
import { monthOptionsPlusYear } from "../utils/utils";

const filterStyles = {
    status: { width: 150 }
};

const EuroField = ({ record = {} }) => <span>€{record.totalPrice}</span>;

const currentMonth = moment().format("MM");
const currentYear = moment().year();
const defaultValue = `${currentMonth}-${currentYear}`;

// const today = new Date();
// const day = today.getDate();
// const month = today.getMonth() + 1;
// const year = today.getFullYear();
// const newDefaultValue = `${day}-${month}-${year}`;

const BookingsFilter = withStyles(filterStyles)(({ classes, ...props }) => {
    const locale = useLocale();

    return (
        <Filter {...props}>
            <ReferenceInput source="propertyId" reference="properties">
                <SelectInput optionText={choice => `${choice.propertyName}`} />
            </ReferenceInput>
            <DateInput source="createdDate" />
            <SelectInput source="month" choices={monthOptionsPlusYear({ locale })} alwaysOn allowEmpty={false} />
            <NumberInput source="totalPrice" />
        </Filter>
    );
});

const BookingsList = ({ classes, permissions, ...props }) => {
    return (
        <List {...props} bulkActionButtons={false} sort={{ field: "createdDate", order: "DESC" }} filterDefaultValues={{ month: defaultValue }} filters={<BookingsFilter />}>
            <Datagrid>
                <ReferenceField source="propertyId" reference="properties" link="show">
                    <TextField source="propertyName" />
                </ReferenceField>
                <DateField locales="en-GB" source="createdDate" />
                <DateField locales="en-GB" source="arrivalDate" />
                <TextField source="arrivalTime" />
                <DateField locales="en-GB" source="departureDate" />
                <TextField source="departureTime" />
                <TextField source="numPax" />
                <TextField source="numNights" />
                {permissions !== "fixed" && <EuroField source="totalPrice" />}
                <TextField source="bookingChannel" />
            </Datagrid>
        </List>
    );
};

export default BookingsList;
