import React from "react";
import { Route } from "react-router-dom";
import Calendar from "./calendar/calendar";
import Settings from "./settings/settings";
import User from "./user/user";
import Utilities from "./utilities/utilities";
import Reports from "./reports/reports";

const routes = [
    <Route key="calendar" path="/calendar" component={Calendar} />,
    <Route key="settings" path="/user" component={User} />,
    <Route key="user" path="/settings" component={Settings} />,
    <Route key="utilities" path="/utilities" component={Utilities} />,
    <Route key="reports" path="/reports" component={Reports} />
];

export default routes;
