import PropertiesList from "./PropertiesList";
import PropertiesShow from "./PropertiesShow";
import BusinessIcon from "@material-ui/icons/Business";

const Properties = {
    list: PropertiesList,
    show: PropertiesShow,
    icon: BusinessIcon
};

export default Properties;
