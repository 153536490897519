import React from "react";
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, ReferenceField, useTranslate } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { saveAs } from "file-saver";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const filterStyles = {
    status: { width: 150 }
};

const DownloadField = ({ source, record = {} }) => {

    const translate = useTranslate();
    
    return (
        <Button color="primary" onClick={() => saveFile(record[source])} label="resources.documents.fields.download">
            <CloudDownloadIcon />
            <span style={{ paddingLeft: "0.5em" }}>{translate("resources.documents.download")}</span>
        </Button>
    );
};
const saveFile = file => {
    saveAs(file, file.split(".com/").pop());
};

const BookingsFilter = withStyles(filterStyles)(({ classes, ...props }) => {
    return (
        <Filter {...props}>
            <ReferenceInput source="propertyId" reference="properties">
                <SelectInput optionText={choice => `${choice.propertyName}`} />
            </ReferenceInput>
        </Filter>
    );
});

const BookingsList = ({ classes, ...props }) => (
    <List {...props} bulkActionButtons={false} sort={{ field: "id", order: "DESC" }} filters={<BookingsFilter />}>
        <Datagrid>
            <ReferenceField source="propertyId" reference="properties" link="show">
                <TextField source="propertyName" />
            </ReferenceField>
            <TextField source="fileName" />
            <DownloadField source="fileURL" />
        </Datagrid>
    </List>
);

export default BookingsList;
