import React from "react";
import { List, Datagrid, TextField, Filter, ReferenceInput, SelectInput, ReferenceField, DateField, useTranslate, useLocale } from "react-admin";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/ca";
import { monthOptions } from "../utils/utils";

const currentMonth = moment().format("MM");
const currentYear = moment().year();
const defaultValue = `${currentMonth}-${currentYear}`;

const styles = {
    headerRow: {
        borderLeftColor: "white",
        borderLeftWidth: 5,
        borderLeftStyle: "solid"
    },
    comment: {
        width: 250,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
};

const filterStyles = {
    status: { width: 150 }
};

const ItemsFilter = withStyles(filterStyles)(({ classes, ...props }) => {
    const locale = useLocale();

    return (
        <Filter {...props}>
            <SelectInput source="month" choices={monthOptions({ locale })} alwaysOn allowEmpty={false} />
            <ReferenceInput source="propertyId" reference="properties">
                <SelectInput optionText={choice => `${choice.propertyName}`} />
            </ReferenceInput>
        </Filter>
    );
});

const EuroField = ({ record = {} }) => <span>€{record.productCost}</span>;

const Aside = ({ data, ids }) => {
    const translate = useTranslate();

    const total = ids
        .map(id => data[id])
        .reduce((total, item) => total + parseFloat(item.productCost), 0)
        .toFixed(2);

    const totalProducts = ids.map(id => data[id]).length;

    return (
        <div style={{ marginLeft: 15 }}>
            <Typography variant="subtitle2">
                {translate("resources.items.amountTotal")} {totalProducts}
            </Typography>
            <Typography variant="subtitle2">
                {translate("resources.items.costTotal")} €{total}
            </Typography>
        </div>
    );
};

const ItemsList = ({ classes, ...props }) => (
    <List {...props} aside={<Aside />} bulkActionButtons={false} filters={<ItemsFilter />} sort={{ field: "dateOut", order: "DESC" }} filterDefaultValues={{ month: defaultValue }} perPage={100}>
        <Datagrid>
            <DateField locales="en-GB" source="dateOut" />
            <ReferenceField source="propertyId" reference="properties" link="show">
                <TextField source="propertyName" />
            </ReferenceField>
            <TextField source="productName" />
            <TextField source="productQuantity" />
            <EuroField source="productCost" />
            <TextField source="storagePaidBy" />
        </Datagrid>
    </List>
);

export default withStyles(styles)(ItemsList);
