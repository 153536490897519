import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Logo from "./Logo";
import Background from "./background.jpg";
import authProvider from "../authProvider";

import { Notification } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";
import { lightTheme } from "./themes";

const useStyles = makeStyles(theme => ({
    main: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    card: {
        minWidth: 300,
        marginTop: "6em"
    },
    logo: {
        margin: "1em",
        display: "flex",
        justifyContent: "center"
    },
    form: {
        padding: "0 1em 1em 1em"
    },
    input: {
        marginTop: "1em"
    },
    actions: {
        display: "block",
        padding: "0 1em 0 1em"
    },
    button: {
        marginBottom: 15
    },
    link: {
        cursor: "pointer",
        textAlign: "center",
        display: "block",
        marginBottom: 5
    }
}));

const renderInput = ({ meta: { touched, error } = { touched: false, error: undefined }, input: { ...inputProps }, ...props }) => (
    <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />
);

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const [isResetting, setReset] = useState(false);
    const [email, setEmail] = useState("");
    const history = useHistory();

    useEffect(() => {
        const { token } = queryString.parse(location.search);
        if (token) {
            authProvider
                .loginFromToken({ token })
                .then(() => history.push("/"))
                .catch(error => {
                    notify("auth.auth_check_error", "warning");
                });
        }
    }, [location.search, history, notify]);

    const handleSubmit = auth => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : "/").catch(error => {
            setLoading(false);
            notify(typeof error === "string" ? error : typeof error === "undefined" || !error.message ? "ra.auth.sign_in_error" : error.message, "warning");
        });
    };

    const handleReset = () => {
        setLoading(true);
        authProvider
            .reset({ email })
            .then(error => {
                setLoading(false);
                notify("auth.reset_success");
            })
            .catch(error => {
                setLoading(false);
                notify("auth.reset_error", "warning");
            });
    };

    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate("ra.validation.required");
        }
        if (!values.password) {
            errors.password = translate("ra.validation.required");
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.logo}>
                                <Logo color="#425e9e" />
                            </div>

                            <div className={classes.form}>
                                {!isResetting ? (
                                    <React.Fragment>
                                        <div className={classes.input}>
                                            <Field autoFocus name="username" component={renderInput} label={translate("ra.auth.username")} disabled={loading} />
                                        </div>
                                        <div className={classes.input}>
                                            <Field name="password" component={renderInput} label={translate("ra.auth.password")} type="password" disabled={loading} />
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className={classes.input}>
                                            <TextField
                                                name="email"
                                                onChange={event => setEmail(event.target.value)}
                                                label={translate("resources.user.email")}
                                                type="email"
                                                disabled={loading}
                                                fullWidth
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                            <div className={classes.actions}>
                                {!isResetting ? (
                                    <React.Fragment>
                                        <Button className={classes.button} variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                                            {translate("ra.auth.sign_in")} {loading && <CircularProgress size={25} thickness={2} />}
                                        </Button>

                                        <Link className={classes.link} disabled={loading} onClick={() => setReset(true)}>
                                            {translate("auth.reset")}
                                        </Link>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Button className={classes.button} variant="contained" color="primary" disabled={loading || !email} fullWidth onClick={handleReset}>
                                            {translate("auth.sent_reset")} {loading && <CircularProgress size={25} thickness={2} />}
                                        </Button>
                                        <Button className={classes.button} variant="contained" color="primary" fullWidth onClick={() => setReset(false)}>
                                            {translate("ra.action.back")}
                                        </Button>
                                    </React.Fragment>
                                )}
                            </div>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = props => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
