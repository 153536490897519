import * as React from "react";
import { useState, useEffect } from "react";
import { useDataProvider, Loading, Error, Authenticated, Title, useTranslate, showNotification } from "react-admin";
import { Card, CardContent, FormControl, TextField, Button, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: 20,
        minWidth: 250
    }
}));

const User = ({ location }) => {
    const dispatch = useDispatch();

    const dataProvider = useDataProvider();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("***********");

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    function saveChanges() {
        dataProvider
            .patch("user", { data: { password, email } })
            .then(({ data }) => {
                dispatch(showNotification("resources.user.saveSuccess"));
            })
            .catch(error => {
                dispatch(showNotification("resources.user.saveError", "warning"));
            });
    }

    useEffect(() => {
        dataProvider
            .get("user")
            .then(({ data }) => {
                setEmail(data.user.email);
                setLoading(false);
            })
            .catch(error => {
                setError(true);
                setLoading(false);
            });
    }, [dataProvider]);

    if (loading) return <Loading />;
    if (error) return <Error error="misc.fetchError" />;

    return (
        <Authenticated location={location}>
            <Card>
                <Title title={translate("resources.user.name")} />
                <CardContent>
                    <FormControl className={classes.formControl} fullWidth>
                        <TextField
                            label={translate("resources.user.email")}
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            inputProps={{
                                name: "email",
                                id: "email"
                            }}
                        />
                    </FormControl>
                    <br />

                    <FormControl className={classes.formControl} fullWidth>
                        <TextField
                            label={translate("ra.auth.password")}
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            type="password"
                            inputProps={{
                                name: "password",
                                id: "password"
                            }}
                        />
                    </FormControl>

                    <br />

                    <Button variant="contained" color="primary" onClick={() => saveChanges()}>
                        {translate("resources.user.save")}
                    </Button>
                </CardContent>
            </Card>
        </Authenticated>
    );
};

export default User;
