import * as React from "react";
import { useState, useEffect } from "react";
import { useDataProvider, Loading, Error, Authenticated, Title, useTranslate, showNotification } from "react-admin";
import { Card, CardContent, FormControl, TextField, Button, makeStyles, Select, InputLabel, MenuItem, Grid, Typography, CircularProgress, InputAdornment } from "@material-ui/core";
import { useDispatch } from "react-redux";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: 20,
        minWidth: 250
    },
    spinner: {
        marginRight: 25
    }
}));

const Utilities = ({ location }) => {
    const dispatch = useDispatch();
    const [properties, setProperties] = useState([]);
    const dataProvider = useDataProvider();
    const [data, setValue] = useState({
        gasProviderName: "",
        gasTitleName: "",
        gasCIF: "",
        gasCUP: "",
        electricityProviderName: "",
        electricityTitleName: "",
        electricityCIF: "",
        electricityCUP: "",
        waterProviderName: "",
        waterTitleName: "",
        waterCIF: "",
        insuranceProviderName: "",
        insuranceTitleName: "",
        insuranceCIF: "",
        insurancePolicyNumber: "",
        internetProviderName: "",
        internetTitleName: "",
        internetCIF: "",
        internetLineNumber: ""
    });

    const [original, setOriginal] = useState({});
    const [currentProperty, setCurrentProperty] = useState("");
    const [loading, setLoading] = useState(true);
    const [loadingProperty, setLoadingProperty] = useState(false);

    const [error, setError] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();

    function requestChange() {
        let changes = difference(data, original);

        if (Object.keys(changes).length > 0) {
            dataProvider
                .patch(`utilities/${currentProperty}`, { data: changes })
                .then(({ data }) => {
                    dispatch(showNotification("resources.utilities.saveSuccess"));
                })
                .catch(error => {
                    dispatch(showNotification("resources.utilities.saveError", "warning"));
                });
        } else {
            dispatch(showNotification("resources.utilities.saveSuccess"));
        }
    }

    function difference(object, base) {
        function changes(object, base) {
            return _.transform(object, function (result, value, key) {
                if (!_.isEqual(value, base[key])) {
                    result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
                }
            });
        }
        return changes(object, base);
    }

    function setProperty(id) {
        setCurrentProperty(id);

        setLoadingProperty(true);
        dataProvider
            .get(`utilities/${id}`)
            .then(({ data }) => {
                setValue(data);
                setOriginal(data);
                setLoadingProperty(false);
            })
            .catch(error => {
                setError(true);
                setLoadingProperty(false);
            });
    }

    useEffect(() => {
        dataProvider
            .get("properties")
            .then(({ data }) => {
                setProperties(data);
                setLoading(false);
            })
            .catch(error => {
                setError(true);
                setLoading(false);
            });
    }, [dataProvider]);

    if (loading) return <Loading />;
    if (error) return <Error error="misc.fetchError" />;

    return (
        <Authenticated location={location}>
            <Card>
                <Title title={translate("resources.utilities.name")} />
                <CardContent>
                    <Typography variant="body1" gutterBottom>
                        {translate("resources.utilities.select_property")}
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="property">{translate("resources.utilities.property")}</InputLabel>
                        <Select
                            value={currentProperty}
                            onChange={event => setProperty(event.target.value)}
                            inputProps={{
                                name: "property",
                                id: "property"
                            }}
                            endAdornment={<InputAdornment position="end">{loadingProperty && <CircularProgress className={classes.spinner} size={20} />}</InputAdornment>}
                        >
                            {properties.map(property => {
                                return <MenuItem key={property.id} value={property.id}>{property.propertyName}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>

                    {currentProperty && !loadingProperty && (
                        <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                                {translate("resources.utilities.gas")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.provider_name")}
                                            value={data.gasProviderName}
                                            onChange={event => setValue({ ...data, gasProviderName: event.target.value })}
                                            inputProps={{
                                                name: "gasProviderName",
                                                id: "gasProviderName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.title_name")}
                                            value={data.gasTitleName}
                                            onChange={event => setValue({ ...data, gasTitleName: event.target.value })}
                                            inputProps={{
                                                name: "gasTitleName",
                                                id: "gasTitleName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cif")}
                                            value={data.gasCIF}
                                            onChange={event => setValue({ ...data, gasCIF: event.target.value })}
                                            inputProps={{
                                                name: "gasCIF",
                                                id: "gasCIF"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cups")}
                                            value={data.gasCUP}
                                            onChange={event => setValue({ ...data, gasCUP: event.target.value })}
                                            inputProps={{
                                                name: "gasCUP",
                                                id: "gasCUP"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography variant="h5" gutterBottom>
                                {translate("resources.utilities.electricity")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.provider_name")}
                                            value={data.electricityProviderName}
                                            onChange={event => setValue({ ...data, electricityProviderName: event.target.value })}
                                            inputProps={{
                                                name: "electricityProviderName",
                                                id: "electricityProviderName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.title_name")}
                                            value={data.electricityTitleName}
                                            onChange={event => setValue({ ...data, emelectricityTitleNameail: event.target.value })}
                                            inputProps={{
                                                name: "electricityTitleName",
                                                id: "electricityTitleName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cif")}
                                            value={data.electricityCIF}
                                            onChange={event => setValue({ ...data, electricityCIF: event.target.value })}
                                            inputProps={{
                                                name: "electricityCIF",
                                                id: "electricityCIF"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cups")}
                                            value={data.electricityCUP}
                                            onChange={event => setValue({ ...data, electricityCUP: event.target.value })}
                                            inputProps={{
                                                name: "electricityCUP",
                                                id: "electricityCUP"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography variant="h5" gutterBottom>
                                {translate("resources.utilities.water")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.provider_name")}
                                            value={data.waterProviderName}
                                            onChange={event => setValue({ ...data, waterProviderName: event.target.value })}
                                            inputProps={{
                                                name: "waterProviderName",
                                                id: "waterProviderName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.title_name")}
                                            value={data.waterTitleName}
                                            onChange={event => setValue({ ...data, waterTitleName: event.target.value })}
                                            inputProps={{
                                                name: "waterTitleName",
                                                id: "waterTitleName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cif")}
                                            value={data.waterCIF}
                                            onChange={event => setValue({ ...data, waterCIF: event.target.value })}
                                            inputProps={{
                                                name: "waterCIF",
                                                id: "waterCIF"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography variant="h5" gutterBottom>
                                {translate("resources.utilities.internet")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.provider_name")}
                                            value={data.internetProviderName}
                                            onChange={event => setValue({ ...data, internetProviderName: event.target.value })}
                                            inputProps={{
                                                name: "internetProviderName",
                                                id: "internetProviderName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.title_name")}
                                            value={data.internetTitleName}
                                            onChange={event => setValue({ ...data, internetTitleName: event.target.value })}
                                            inputProps={{
                                                name: "internetTitleName",
                                                id: "internetTitleName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cif")}
                                            value={data.internetCIF}
                                            onChange={event => setValue({ ...data, internetCIF: event.target.value })}
                                            inputProps={{
                                                name: "internetCIF",
                                                id: "internetCIF"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.line_number")}
                                            value={data.internetLineNumber}
                                            onChange={event => setValue({ ...data, internetLineNumber: event.target.value })}
                                            inputProps={{
                                                name: "internetLineNumber",
                                                id: "internetLineNumber"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Typography variant="h5" gutterBottom>
                                {translate("resources.utilities.insurance")}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.provider_name")}
                                            value={data.insuranceProviderName}
                                            onChange={event => setValue({ ...data, insuranceProviderName: event.target.value })}
                                            inputProps={{
                                                name: "insuranceProviderName",
                                                id: "insuranceProviderName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.title_name")}
                                            value={data.insuranceTitleName}
                                            onChange={event => setValue({ ...data, insuranceTitleName: event.target.value })}
                                            inputProps={{
                                                name: "insuranceTitleName",
                                                id: "insuranceTitleName"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.cif")}
                                            value={data.insuranceCIF}
                                            onChange={event => setValue({ ...data, insuranceCIF: event.target.value })}
                                            inputProps={{
                                                name: "insuranceCIF",
                                                id: "insuranceCIF"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl className={classes.formControl} fullWidth>
                                        <TextField
                                            label={translate("resources.utilities.policy_number")}
                                            value={data.insurancePolicyNumber}
                                            onChange={event => setValue({ ...data, insurancePolicyNumber: event.target.value })}
                                            inputProps={{
                                                name: "insurancePolicyNumber",
                                                id: "insurancePolicyNumber"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" onClick={() => requestChange()}>
                                {translate("resources.utilities.request_change")}
                            </Button>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        </Authenticated>
    );
};

export default Utilities;
