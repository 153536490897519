import catalanMessages from "@joshfradley/ra-language-catalan";

const messages = {
    ...catalanMessages,
    auth: {
        reset: "Has oblidat la contrasenya?",
        sent_reset: "Envia una contrasenya",
        reset_error: "S'ha produït un error en enviar la contrasenya",
        reset_success: "S'ha enviat una nova contrasenya al vostre correu electrònic"
    },
    resources: {
        settings: {
            name: "Configuració",
            language: "Llenguatge",
            theme: {
                name: "Tema",
                light: "Llum",
                dark: "Fosc"
            }
        },
        dashboard: {
            name: "Panell",
            revenue: "Ingressos",
            num_bookings: "Reserves",
            num_properties: "Propietats",
            rating: "Valoració",
            welcome: {
                title: "Benvingut",
                subtitle: "Aquest és el vostre panell personalitzat on podeu veure el calendari, les reserves i les ressenyes de totes les vostres propietats"
            },
            charts: {
                occupancy: "Ocupación (%)",
                adr: "ADR",
                revenue: "Ingressos",
                currentYear: "Current year",
                previousYear: "Previous year"
            }
        },
        bookings: {
            name: "Reserva |||| Reserves",
            fields: {
                createdDate: "Creat",
                arrivalDate: "Arribada",
                arrivalTime: "Hora d'arribada",
                departureDate: "Sortida",
                departureTime: "Hora de sortida",
                totalPrice: "Total",
                propertyId: "Propietat",
                numPax: "Pax",
                numNights: "Nits",
                bookingChannel: "Canal",
                month: "Mes"
            }
        },
        reviews: {
            name: "Valoració |||| Valoracions",
            fields: {
                reviewDate: "Data",
                propertyId: "Propietat",
                comment: "Comentari",
                commentPrivate: "Comentari Privat",
                rating: "Valoració",
                bookingChannel: "Canal",
                sourceName: "Canal de Valoració",
                reviewDateRange: "Interval de dates",
                month: "Mes"
            }
        },
        properties: {
            name: "Propietat |||| Propietats",
            fields: {
                propertyName: "Nom Propietat",
                propertyCapacity: "Capacitat",
                propertyNumBedrooms: "Dormitoris",
                propertyNumBathrooms: "Banys",
                propertyAverage: "Mitjana Valoració",
                createdDate: "Creat",
                propertyCadastral: "Número de Cadastral",
                propertyLicense: "Llicència núm",
                propertySize: "Mida (m2)",
                propertyAddress: "Adreça",
                propertyChannel: "Canals",
                propertyPhotos: "Fotos"
            },
            reportIssue: "Informa d'un problema",
            reportIssueComment: "El teu comentari",
            reportIssueDesc: "Informeu les inexactituds, errors o afegiu qualsevol comentari",
            feedbackSent: "S'han enviat comentaris",
            feedbackError: "S'ha produït un error en enviar comentaris"
        },
        calendar: { name: "Calendari", properties: "Propietats", occupancy: "Ocupación" },
        maintenance: {
            name: "Manteniment",
            fields: {
                propertyId: "Propietat",
                taskDesc: "Tasca",
                taskCreation: "Creat",
                month: "Mes",
                taskCost: "Coste de reparación/material",
                taskCreationRangeOptions: { currentMonth: "Aquest mes" },
                taskPaidBy: "Pagat per"
            }
        },
        items: {
            name: "Parament de la llar",
            fields: {
                propertyId: "Propietat",
                productName: "Producte",
                productQuantity: "Quantitat",
                productCost: "Cost",
                dateOut: "Data",
                month: "Mes",
                storagePaidBy: "Pagat per"
            },
            amountTotal: "Quantitat Total:",
            costTotal: "Cost Total:"
        },
        user: {
            name: "Usuari",
            save: "Guardar canvis",
            email: "Correu electrònic",
            saveSuccess: "Canvi desat",
            saveError: "S'ha produït un error en desar els canvis"
        },
        utilities: {
            name: "Utilitats",
            property: "Propietat",
            gas: "Gas",
            provider_name: "Nom del proveïdor",
            title_name: "Títol",
            cif: "CIF",
            cups: "CUPS",
            electricity: "Electricitat",
            water: "Aigua",
            internet: "Internet",
            insurance: "Assegurança",
            line_number: "Número de línia",
            policy_number: "Número de la policia",
            request_change: "Canvieu la sol·licitud",
            saveSuccess: "Petició enviada",
            saveError: "S'ha produït un error en enviar equest",
            select_property: "Seleccioneu una propietat"
        },
        reports: {
            name: "Informes",
            label: "Nom",
            from: "Des de",
            to: "Fins",
            nights: "Nits",
            people: "Personas",
            channel: "Persones",
            revenue: "Ingressos",
            commissionCompany: "Comissió Stay U-nique",
            channelCommission: "Comissió Plataforma",
            cleaning: "Neteja",
            laundry: "Bugaderia",
            maintenance: "Manteniment",
            extras: "Altres extres",
            totalCosts: "Total Despeses",
            totalCostsTax: "IVA Despeses",
            totalInvoice: "Total a facturar",
            ownerNetProfit: "Net Propietari",
            ownerNetProfitNoVAT: "Net Propietari (sense IVA)",
            irpf: "IRPF",
            bills: "Subministraments",
            month: "Mes",
            rent: "Lloguer",
            exportExcel: "Exportar Excel",
            type: "Tipus estada",
            shortTerm: "Estades turístiques",
            midTerm: "Estades mensuals",
            noData: "Sense dades",
            comment: "El vostre comentari",
            approveReport: "Aprovar",
            rejectReport: "No Aprovar",
            send: "Enviar",
            rejectReportComment: "Deixa el teu comentari aquí. El Departament de Propietaris es posarà en contacte amb tu quan el rebi",
            reportApproved: "Gràcies per aprovar la liquidació. En breu el Departament de Propietaris us contactarà per enviar la factura i fer transferència",
            chargedCompany: "Cobrat per Stay U-nique",
            chargedOwner: "Cobrat per propietari",
            toTransfer: "A transferir a propietari",
            toTransferSU: "A transferir a Stay-u-nique",
            netRevenue: "Base Imposable"
        },
        documents: {
            name: "Documentos",
            fields: {
                propertyId: "Propiedad",
                fileName: "Nombre de archivo",
                fileURL: "Descargar"
            },
            download: "Descargar archivo"
        }
    },
    misc: {
        fetchError: "Error en obtenir dades"
    }
};

export default messages;
